/*
 * @Author: your name
 * @Date: 2020-11-04 22:09:19
 * @LastEditTime: 2023-09-20 10:27:13
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\login\index.js
 */
import {
  imgCode,
  // login,
  // 手机号码登陆
  weblogin,
  // 获取手机验证码
  phoneLogin,
  // 登录次数
  loginTimes,
  changePassword,
  // getTwoLogin, //二次认证
  toOldVer, //判断是否跳转旧版
} from "./api";
import {
  getAlert,
} from "@/utils/api";
// import {
//   setToken

// } from "@/utils/auth";
// import {
//   wordSearch
// } from "@/utils/api.js";

import {
  publicPath,
  versionSize,
  env
} from "@/common/constant";
import CryptoJS from "crypto-js";
import { getprivacyPolicyInfo } from "@/views/home/api";
// import {
//   setToken
// } from "@/utils/auth";
export default {
  name: "carrousel",
  data() {
    return {
      publicPath,
      loginForm: {
        // username: "708111547",
        // password: "123456a",
        username: "",
        password: "",
        imgCode: "",
      },
      // 点击按钮loadingactive
      isloading: false,
      // 图片验证码
      imgURL: "",
      // 账号密码输入次数
      intputNumber: 0,
      // 验证码
      YZcode: false,
      // 验证码错误提示
      codeerror: false,
      active: true,
      // 账号密码错误
      loginerror: false,
      // 手机号登录
      loginPhone: {
        phone: "",
        code: "",
      },
      // 手机验证码错误
      loginerrorPH: false,
      // 验证码倒计时
      codevalue: "获取验证码",
      clickCancle: false,
      scanhide: true,
      saomalogin: "扫码登录更安全",
      checked: true,
      // 加密解密
      addpadd: false,
      visible: false,
      userAgrement: false, //用户协议
      visibleUser: false,
      testPhone: "",
      testPhoneCode: "",
      snedCOde: "获取验证码",
      codeDisabled: false,
      Agree: {
        name: "",
        phone: "",
        code: "",
        email: "",
        password: "",
        surePassword: "",
      },
      testzzc: "",
      xingming: false,
      shoujihao: false,
      yanzhengma: false,
      youxiang: false,
      mima: false,
      mimabuyizhi: false,
      mimabuzhongwen: false,
      mimabuchangdu: false,
      // 加密后账号密码
      twologins: {},
      oldSHow: true,
      accountError: false,
      time:60,
      codeTime:null,
      userAgreement:false,
      privacyPolicyInfor:{},
      mimaC:false
    };
  },
  // swiper  轮播
  mounted() {
    // new this.$Swiper(".swiper-container", {
    //   loop: true,
    //   autoplay: true,
    //   pagination: {
    //     el: ".swiper-pagination"
    //   }
    // });
    // 图形验证码
    this.getIdentifyingCode();
    // 登录次数
    this.loginTime();
    // 账号密码
    var obj = JSON.parse(localStorage.getItem("userName"));
    if (obj) {
      this.loginForm = obj;
      this.addpadd = true;
    }
    this.loginForm.imgCode = "";
    this.ver();
    this.getprivacyPolicy()
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
          if(query.questionId) {
            this.redirect += '&questionId=' + query.questionId
          }
        }
      },
      immediate: true,
    },
    'Agree.password': {
      handler: function (val) {
        this.Agree.password = this.Agree.password.replace(/\s*/g, "")
        if(!this.Agree.password){
          this.mima = true
          this.mimabuzhongwen = false
          this.mimabuchangdu = false
          return
        }else if(!/^[^\u4e00-\u9fa5]+$/.test(this.Agree.password)){
          this.mimabuzhongwen = true
          this.mima = false
          this.mimabuchangdu = false
          return
        } else if(this.Agree.password.length < 8 || this.Agree.password.length > 20 ){
          this.mimabuchangdu = true
          this.mimabuzhongwen = false
          this.mima = false
          return
        } else {
          this.mimabuchangdu = false
          this.mimabuzhongwen = false
          this.mima = false
        }
      }
    },
    'Agree.surePassword': {
      handler: function (val) {
        this.Agree.surePassword = this.Agree.surePassword.replace(/\s*/g, "")
        if(!this.Agree.surePassword){
          this.mimaC = true
          this.mimabuyizhi = false
          return
        } else if(this.Agree.password != this.Agree.surePassword){
          this.mimabuyizhi = true
          this.mimaC = false
          return
        } else {
          this.mimabuyizhi = false
          this.mimaC = false
        }

      }
    },
    'loginForm.password': {
      handler: function (val) {
        this.loginForm.password = this.loginForm.password.replace(/\s*/g, "")
      }
    },
    'loginForm.username': {
      handler: function (val) {
        this.loginForm.username = this.loginForm.username.replace(/\s*/g, "")
      }
    },
  },
  methods: {
    changeimgCode(){
      if(!this.Agree.code){
        this.yanzhengma = true
      } else {
        this.yanzhengma = false
      }
    },
    
    //清除空格
    trimEmpty(value,type){
      this.loginForm[type]=value.trim()
    },
    
    changeMima(type){
      if(!this.Agree.password &&type=='psd'){
        this.mima = true
        return
      }
      if(!this.Agree.surePassword &&type!=='psd'){
        this.mimaC = true
        return
      }
    },
    // 版本
    ver() {
      let params = {
        configKey: "IS_SWITCH_OLDANDNEW_VERSION",
      };
      toOldVer(params).then((res) => {
        if (res.data.value == "1") {
          this.oldSHow = true;
        } else {
          this.oldSHow = false;
        }
      });
    },
    // 跳转旧版本
    toOldVersion() {
      window.location.href = versionSize;
    },
    // 登录获取验证码
    getPhoneCode() {
      // this.timeReset()
      if(this.codeTime || this.codeDisabled){
        return
      }
      this.time = 60;
      this.codeDisabled = true;
      this.codeTime = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.codeTime);
          this.codeTime = null
          this.codeDisabled = false;
          this.snedCOde = "获取验证码";
          this.time = 60;
          return;
        }
        this.time--;
        this.snedCOde = '已发送' + this.time + "s";
      }, 1000);
      phoneLogin({
        account: this.loginForm.username,
        phone: this.Agree.phone,
        msgType: 3,
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message.warning(res.data.msg);
          this.time = 0;
        }
      });
    },
    // 验证码倒计时
    timeReset() {
      if(this.codeTime){
        return
      }
      this.time = 60;
      this.codeDisabled = true;
      this.codeTime = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.codeTime);
          this.codeTime = null
          this.codeDisabled = false;
          this.snedCOde = "获取验证码";
          this.time = 60;
          return;
        }
        this.time--;
        this.snedCOde = "已发送" + this.time + "s";
      }, 1000);
    },

    handleOk() {
      // this.$emit('update:visible', false)
    },
    // 登录次数
    loginTime() {
      loginTimes().then((res) => {
        if (res.data.data > 2) {
          this.YZcode = true;
          this.intputNumber = res.data.data;
        }
      });
    },
    // 扫码登录
    toscanzzz() {
      if (this.scanhide) {
        this.saomalogin = "返回主页登录";
      } else {
        this.saomalogin = "扫码登录更安全";
      }
      this.scanhide = !this.scanhide;
    },

    // 登录方式切换
    loginChagne(data) {
      this.active = data;
    },
    // 获取手机验证码
    getCode() {
      this.$message.warning("功能暂未开放");

      // if (this.loginPhone.phone == '') {
      //   this.$message.warning('请输入手机号')
      //   return;
      // } else if (this.loginForm.username == '') {
      //   this.$message.warning('请输入账号')
      //   return;
      // }
      // let data = {
      //   account: this.loginForm.username,
      //   phone: this.loginPhone.phone,
      //   msgType: 2
      // }
      // phoneLogin(data).then(res => {
      //   if (res.data.code == 0) {
      //     var totaltime = 6
      //     this.clickCancle = true
      //     var times = setInterval(() => {
      //       totaltime--;
      //       this.codevalue = '已发送' + totaltime + 's'
      //       if (totaltime == 0) {
      //         clearInterval(times);
      //         this.codevalue = '获取验证码';
      //         this.clickCancle = false;
      //       };
      //     }, 1000)
      //   }
      // })
    },
    // 图片验证码
    getIdentifyingCode() {
      imgCode().then((res) => {
        this.imgURL = res.data.img;
      });
    },
    // 验证码输入框change事件
    handleChangeClick(e) {
    },
    handleChange(e) {
      this.checked = e.target.checked;
    },
    // 获取隐私政策数据
    getprivacyPolicy() {
      let data = {
        loginPlant: "PC"
      };
      return getprivacyPolicyInfo(data);
    },

    async handlerLogin() {
      let res = await this.getprivacyPolicy()
      if(res.data.list.length > 0) {
        this.privacyPolicyInfor = res.data.list[0]
      }
      // 如果缓存里没有获取不到 弹出弹窗
      if(!localStorage.getItem("agreePrivacy") ) {
        this.userAgreement = true
        return
      }
      // 点击登陆之前先判断缓存里是否同意过 如果为false 则弹出弹窗
      if( localStorage.getItem("agreePrivacy") &&localStorage.getItem('agreePrivacy') == 'false' ) {
        this.userAgreement = true
        return
      }
      // 如果缓存里为true 版本号和当前接口调用中的不一样 也会弹出弹窗
      if(localStorage.getItem('agreePrivacy') == 'true' && localStorage.getItem('UserAgreementVersionNumber') !== this.privacyPolicyInfor.pageKey ){
        this.userAgreement = true
        return
      }
      if (this.checked) {
        let obj = JSON.stringify(this.loginForm);
        localStorage.setItem("userName", obj);
      } else if (localStorage.getItem("userName") != null) {
        let obj = JSON.parse(localStorage.getItem("userName"));
        if (obj.username == this.loginForm.username) {
          localStorage.clear("userName");
        }
      }
      // // 每次点击登录隐藏错误提示
      this.loginerror = false;
      this.isloading = true;
      if (this.intputNumber > 2) {
        this.isloading = false;
        this.YZcode = true;
        if (this.loginForm.imgCode == "") {
          this.$message.warning("请输入验证码");
          return;
        }
      }
      let CRYPTOJSKEY = "abcdefgabcdefg12";
      let submitobj = {};
      // 密码加密
      var plaintText = this.loginForm.password;
      var options = {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      };
      var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY);
      var encryptedData = CryptoJS.AES.encrypt(plaintText, key, options);
      var encryptedBase64Str = encryptedData.toString().replace(/\//g, "_");
      // 密码加密
      // this.loginForm.password = encryptedBase64Str;
      submitobj = JSON.parse(JSON.stringify(this.loginForm));
      submitobj.password = encryptedBase64Str;
      submitobj.targetUrl = this.redirect || '';
      this.twologins = submitobj;
      this.$store
        .dispatch("user/login", submitobj)
        .then((res) => {
          this.getIdentifyingCode();
          this.isloading = false;
          if (res.data.msg == "账号不存在") {
            // this.$message.warning(res.data.msg);
            this.accountError = true;
            return;
          }
          if (res.data.msg == "请完善信息") {
            this.visibleUser = true;
            this.userAgrement = true;
            this.Agree.name = res.data.account.userName;
            this.Agree.phone = res.data.account.mobile;
            // this.Agree.phone  =
            //   res.data.account.mobile.substr(0, 3) +
            //   "****" +
            //   res.data.account.mobile.substr(7);
            this.Agree.email = res.data.account.email;
          }
          if (res.data.code == 1002) {
            this.visible = true;
            this.isloading = false;
            this.Agree.phone = res.data.account.mobile;
            this.timeReset();
            this.testzzc =
              res.data.account.mobile.substr(0, 3) +
              "****" +
              res.data.account.mobile.substr(7);
            return;
          }
          if (res.data.code == 21) {
            this.$message.warning(res.data.msg);
            return;
          }
          // else if (res.data.code == 1004) {
          //   this.codeerror = true;
          //   this.YZcode = true;
          //   this.getIdentifyingCode();
          // }

          if (
            this.intputNumber > 2 &&
            res.data.msg == "验证码错误，请重新输入"
          ) {
            this.isloading = false;
            this.YZcode = true;
            this.codeerror = true;
            // this.getIdentifyingCode();
          } else if (res.data.msg == "账号或密码不正确") {
            this.loginerror = true;
            this.intputNumber++;
            this.isloading = false;
            this.codeerror = false;
            // this.getIdentifyingCode();
            if (this.intputNumber > 2) {
              this.YZcode = true;
            }
            this.loginForm.password = "";
          } else if (res.data.code == 0) {
            this.intputNumber = 0;
            getAlert().then((res) => {
              localStorage.setItem("msgArr", JSON.stringify(res.data.list));
            });
            // 集成was登录的时候调用
            if (typeof window._paq !== 'undefined' ) {
              window._paq.push(['setUserId', res.data.account.account]);
              window._paq.push(['setCustomUrl', '/login']);
              window._paq.push(['setDocumentTitle', '登录']);
              window._paq.push(['trackPageView']);
            }
            if (env == "dev") {
              this.$router.push({
                path: this.redirect || "/index",
                query: this.otherQuery,
              });
            } else {
              window.location.href = res.data.redirectUrl;
            }
          } else if (res.data.code != "1001") {
            this.isloading = false;
            this.loginForm.password = "";
            this.$message.warning(res.data.msg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    //二次认证登录loginForm username password
    twoLogin() {
      if (this.testPhoneCode == "") {
        this.$message.warning("请输入验证码");
        return;
      }
      let params = {
        password: this.twologins.password,
        userName: this.twologins.username,
        msgCode: this.testPhoneCode,
        msgType: 1,
      };
      weblogin(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.intputNumber = 0;
            this.$store.dispatch("user/login", this.twologins).then(() => {
              getAlert().then((res) => {
                localStorage.setItem("msgArr", JSON.stringify(res.data.list));
              });
              // 集成was登录的时候调用
              if (typeof window._paq !== 'undefined' ) {
                window._paq.push(['setUserId', res.data.account.account]);
                window._paq.push(['setCustomUrl', '/login']);
                window._paq.push(['setDocumentTitle', '登录']);
                window._paq.push(['trackPageView']);
              }
              if (env == "dev") {
                this.$router.push({
                  path: this.redirect || "/index",
                  query: this.otherQuery,
                });
              } else {
                window.location.href = res.data.redirectUrl;
              }

            });
          } else {
            this.$message.warning("二次验证失败");
          }
        })
        .catch(() => {
          this.$message.warning("二次验证失败");
        });
    },
    // 同意协议
    tongyi() {
      this.userAgrement = false;
      // this.timeReset()
      // 首次登陆统一协议以后原来是直接倒计时发短信 6-9日 CIS-8004 首次登陆重置密码功能优化 改成手动点击才会发送
      // 注释掉以下代码
      // this.getPhoneCode();
    },
    // 不同意协议
    butongyi() {
      this.visibleUser = false;
    },
    // 同意协议  确认修改
    sureChange() {
      if (this.Agree.name == "") {
        this.xingming = true;
        return;
      } else if (this.Agree.phone == "") {
        this.xingming = false;
        this.shoujihao = true;
        return;
      } else if (this.Agree.code == "") {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = true;
        return;
      } else if (this.Agree.email == "") {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = true;
        return;
      } else if (this.Agree.password == "") {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = false;
        this.mima = true;
        return;
      } else if (this.Agree.password != this.Agree.surePassword) {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = false;
        this.mima = false;
        this.mimabuyizhi = true;
        return;
      } else if (!/^[^\u4e00-\u9fa5]+$/.test(this.Agree.password)) {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = false;
        this.mima = false;
        this.mimageshi = false;
        this.mimabuzhongwen = true;
        this.mimabuyizhi = false;
        return;
      }
      // else if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*,.])[\da-zA-Z~!@#$%^&*,.]{8,}$/.test(this.Agree.password)) {
      //   this.xingming = false;
      //   this.shoujihao = false;
      //   this.yanzhengma = false;
      //   this.youxiang = false;
      //   this.mima = false;
      //   this.mimabuzhongwen = false;
      //   this.mimageshi = true;
      //   return;
      // }
      else if (this.Agree.password.length < 8 || this.Agree.password.length > 20 ) {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = false;
        this.mima = false;
        this.mimabuzhongwen = false;
        this.mimabuchangdu = true;
        this.mimabuyizhi = false;
        return;
      }

      //  else if (!(this.Agree.password.test(this.Agree.password))) {
      //   this.$message.warning('密码必须包含一个特殊字符')
      //   return;
      // }
      // else if (
      //   this.Agree.password.indexOf("<") >= 0 ||
      //   this.Agree.password.indexOf(">") >= 0 ||
      //   this.Agree.password.indexOf("'") >= 0 ||
      //   this.Agree.password.indexOf("+") >= 0 ||
      //   this.Agree.password.indexOf(")") >= 0 ||
      //   this.Agree.password.indexOf("/") >= 0 ||
      //   this.Agree.password.indexOf("&") >= 0 ||
      //   this.Agree.password.indexOf(";") >= 0
      // ) {
      //   this.$message.warning("不能包含以下特殊字符:\n <>')+/&;");
      //   return;
      // }
      else {
        this.xingming = false;
        this.shoujihao = false;
        this.yanzhengma = false;
        this.youxiang = false;
        this.mima = false;
        this.mimabuzhongwen = false;
        this.mimabuchangdu = false;
        this.mimabuyizhi = false;
      }
      let data = {
        name: this.Agree.name,
        phone: this.Agree.phone,
        validationCode: this.Agree.code,
        email: this.Agree.email,
        password: this.Agree.password.trim(),
        npassword: this.Agree.surePassword.trim(),
      };
      changePassword(data).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功，请重新登陆");
          this.userAgrement = false;
          this.visibleUser = false;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    quxioaz() {
      this.visibleUser = false;
    },
    // 手机号登录
    handlePHLogin() {
      weblogin().then((res) => {
      });
    },

    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};