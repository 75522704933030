/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2020-11-18 09:00:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";

// post
export function login(data) {
  return http({
    method: "post",
    url: "/webLogin.nd",
    data
  });
}

// 判断是否跳转旧版本
export function toOldVer(params){
  return http({
    method:'get',
    url:'/comm/getSystemConfig.nd',
    params
  })
}
// 获取图形验证码
export function imgCode() {
  return http({
    method: 'get',
    url: '/checkImg2.nd'
  })
}
// 获取手机短信验证码
export function phoneLogin(params) {
  return http({
    method: 'get',
    url: '/getMsmByPhone.nd',
    params
  })
}

// 二次认证登录
export function getTwoLogin(data) {
  return http({
    method: 'post',
    url: '/need/sesondLogin.nd',
    data
  })
}
// 确认修改密码
export function changePassword(data) {
  return http({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    url: '/activeEnterpriseUser/editEnterpriseUser.nd',
    data
  })
}
// 登录接口
export function weblogin(data) {
  return http({
    method: 'post',
    url: '/webLogin.nd',
    data
  })
}

// 登录次数
export function loginTimes(data) {
  return http({
    method: 'post',
    url: '/errorTime.nd',
    data
  })
}